import Venue from './Venue.vue'
import VenueCreate from './Create.vue'
import VenueEdit from './Edit.vue'
import VenueShow from './Show.vue'


export default [
    {
        path: '/venue',
        name: 'Venue',
        component: Venue
    },
    {
        path: '/venue/create',
        name: 'VenueCreate',
        component: VenueCreate
    },
    {
        path: '/venue/edit/:id',
        name: 'VenueEdit',
        component: VenueEdit
    },
    {
        path: '/venue/:id',
        name: 'VenueShow',
        component: VenueShow
    },

]
